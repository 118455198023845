import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
    selectedPlatform: localStorage.getItem('selectedPlatform') || 'sui'
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setSelectedPlatform(state, platform) {
      state.selectedPlatform = platform
      localStorage.setItem('selectedPlatform', platform)
    }
  },
  actions: {
    selectPlatform({ commit }, platform) {
      commit('setSelectedPlatform', platform)
    }
  },
  getters: {
    getSelectedPlatform: (state) => state.selectedPlatform
  }
})
