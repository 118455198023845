<template>
    <div class="flex flex-col md:flex-row items-start justify-between w-full min-h-screen p-4 md:p-10 overflow-y-auto">
        <!-- Left column -->
        <div class="flex flex-col justify-start items-start text-gray-900 dark:text-white w-full md:w-auto">
            <!-- Start Game button -->
            <div class="w-full relative flex mb-6">
                <div class="absolute inset-0">
                    <div class="absolute inset-0">
                        <div class="absolute inset-0 bg-[#1647aa] rounded-[15px] shadow border-2 border-[#778290]"></div>
                        <div class="absolute inset-0 bottom-2 bg-[#2b64d6] rounded-[15px]"></div>
                    </div>
                </div>
                <img class="w-16 h-16 relative ml-6 my-2" src="../assets/rps.svg" alt="RPS icon" />
                <div class="relative text-[#f2f2f2] text-3xl ml-4 self-center">Start Game</div>
            </div>

            <h2 class="text-5xl md:text-7xl font-bowlby mb-6 md:mb-12 mt-6 md:mt-10 text-[#FF77C4]">ONLINE</h2>

            <!-- Info boxes -->
            <div class="w-full md:w-[550px] space-y-4">
                <div class="rounded-2xl p-6 bg-[#523BD4] dark:bg-[#523BD4] border border-indigo-400 dark:border-indigo-600">
                    <h1 class="text-2xl font-bowlby mb-3">Do You Have a</h1>
                    <h1 class="text-2xl font-bowlby mb-3">Disagreement with a Friend?</h1>
                    <div class="rounded-lg p-3 bg-[#523BD4] dark:bg-indigo-950 inline-block">
                        <h1 class="text-base font-normal">Resolve it ONLINE!</h1>
                    </div>
                </div>
                <div class="rounded-2xl p-7 bg-[#523BD4] dark:bg-[#523BD4] border border-indigo-400 dark:border-indigo-600 mt-5">
                    <h1 class="text-2xl font-bowlby mb-3">To play, click</h1>
                    <h1 class="text-2xl font-bowlby mb-3">on Start.</h1>
                    <div class="rounded-lg p-3 bg-[#523BD4] dark:[#523BD4] inline-block">
                        <h1 class="text-sm font-normal">SEND a link to your friend.</h1>
                    </div>
                </div>
            </div>

            <!-- Stats boxes -->
            <div class="flex flex-col md:flex-row justify-between w-full gap-4 mt-6">
                <div class="flex justify-center mt-5 md:mt-0">
                    <div class="w-full md:w-40 px-6 py-4 flex flex-col items-start justify-start relative bg-[#513bd3] rounded-[25px] border border-[#7b66f7]">
                        <div class="w-12 h-12">
                            <img src="../assets/play.png" alt="play" class="">
                        </div>
                        <div class="flex flex-col w-full mt-3">
                            <span class="text-white text-[25px] font-bold leading-[25px]">+12</span>
                            <span class="text-[#9e8eff] text-sm font-normal">Online Players</span>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center mt-5 md:mt-0">
                    <div class="w-full md:w-40 px-6 py-4 flex flex-col items-start justify-start relative bg-[#513bd3] rounded-[25px] border border-[#7b66f7]">
                        <div class="w-12 h-12">
                            <img src="../assets/active_lobby.png" alt="play" class="">
                        </div>
                        <div class="flex flex-col w-full mt-3">
                            <span class="text-white text-[25px] font-bold leading-[25px]">{{ lobbies.length }}</span>
                            <span class="text-[#9e8eff] text-sm font-normal">Active Lobby</span>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center mt-5 md:mt-0">
                    <div class="w-full md:w-40 px-6 py-4 flex flex-col items-start justify-start relative bg-[#513bd3] rounded-[25px] border border-[#7b66f7]">
                        <div class="w-12 h-12">
                            <img src="../assets/reward.png" alt="play" class="">
                        </div>
                        <div class="flex flex-col w-full mt-3">
                            <span class="text-white text-[25px] font-bold leading-[25px]">24.500$</span>
                            <span class="text-[#9e8eff] text-sm font-normal">Daily Reward</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Right column -->
        <div class="flex flex-col justify-start w-full md:w-1/2 mt-8 md:mt-0 md:ms-8">
            <!-- START button -->
            <div class="hidden w-full justify-center md:flex md:justify-end mb-8">
                <div class="relative w-full max-w-md">
                    <div class="absolute inset-0 bg-[#1647aa] rounded-[15px] shadow border-2 border-[#778290]"></div>
                    <div class="absolute inset-0 bottom-2 bg-[#2b64d6] rounded-[15px]"></div>
                    <div class="relative py-4 px-6 text-white text-3xl font-normal text-center">START</div>
                    <button @click="openLobbyModal" class="absolute inset-0 w-full h-full opacity-0"></button>
                </div>
            </div>

            <!-- Lobbies grid -->
            <div class="overflow-y-auto w-full flex-grow scrollbar-hide smooth-scroll">
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    <div 
                        class="bg-[#523BD4] rounded-[25px] border border-[#7C67F7] flex justify-between items-center overflow-hidden h-40 cursor-pointer" 
                        v-for="lobby in lobbies" 
                        :key="lobby.id" 
                        @click="goToGame(lobby.id)"
                    >
                        <h3 class="text-[#a596ff] text-[27px] font-normal ms-5">Lobby #{{ lobby.id.split('-')[0] }}</h3>
                        <img src="../assets/lobby_card.svg" alt="lobby" class="-bottom-10 -right-40 object-cover">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img src="../assets/play_grid.svg" alt="grid" class="fixed bottom-0 right-0 -z-10 w-full md:w-auto">
</template>

<style scoped>
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

.smooth-scroll {
  scroll-behavior: smooth;
}

@media (max-width: 768px) {
    body {
        overflow-y: auto;
    }
}
</style>

<script setup>
import userService from '@/services/userService';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const lobbies = ref([]);

onMounted(async () => {
    const response = await userService.getLobbies();
    lobbies.value = response.data;
});

const goToGame = (lobbyId) => {
  router.push(`play/${lobbyId}`);
};
</script>

