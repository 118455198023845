import axios from 'axios';

const API_URL = 'https://api.rpsbattle.fun/';
// const API_URL = 'http://localhost:3005/';

// Create an Axios instance
const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
      config.headers['Authorization'] = `Bearer ${user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const userService = {
  login: async (signature, address, chain) => {
    try {
      const response = await apiClient.post('/auth/login', { signature, address, chain });
      if (response.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getNonce: async (address) => {
    try {
      const response = await apiClient.post('/auth/nonce', { address });
      return response.data.nonce;
    } catch (error) {
      throw error.response.data;
    }
  },

  logout: () => {
    localStorage.removeItem('user');
  },

  getCurrentUser: () => {
    return JSON.parse(localStorage.getItem('user'));
  },

  createLobby: async () => {
    try {
      const response = await apiClient.post('/lobby');
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getLobbies: async () => {
    try {
      const response = await apiClient.get('/lobby');
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getHistory: async () => {
    try {
      const response = await apiClient.get('/user/history');
      return response.data;
    } catch(error) {
      throw error.response.data;
    }
  }
};

export default userService;
