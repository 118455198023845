<template>
    <img src="../assets/bg_blur.png" alt="bg_blur" class="absolute top-0 left-0">
    <div class="absolute">
        <img src="../assets/login_bg.svg" alt="login_bg" class="absolute bottom-0 right-0">

    </div>
    <div class="relative h-full w-full overflow-hidden flex flex-col md:flex-row justify-between p-4 md:p-10">
        <div class="flex flex-col justify-start items-start text-white mb-8 md:mb-0">
            <h1 class="text-4xl md:text-8xl font-bowlby mb-2 md:mb-8">Rock Paper</h1>
            <h1 class="text-4xl md:text-8xl font-bowlby mb-2 md:mb-8">Scissors</h1>
            <h2 class="text-3xl md:text-7xl font-bowlby mb-4 md:mb-12 text-[#FF77C4]">ONLINE</h2>

            <div class="rounded-2xl p-4 md:p-6 bg-[#523BD4] border border-[#7C67F7] w-full md:w-[430px] mb-4 md:mb-0">
                <h1 class="text-xl md:text-2xl font-bowlby mb-2 md:mb-3">Do You Have a</h1>
                <h1 class="text-xl md:text-2xl font-bowlby mb-2 md:mb-3">Disagreement with a Friend?</h1>
                <div class="rounded-lg p-2 md:p-3 bg-[#3421A3] inline-block">
                    <h1 class="text-sm md:text-base font-normal">Resolve it ONLINE!</h1>
                </div>
            </div>
            <div class="rounded-2xl p-4 md:p-7 bg-[#523BD4] border border-[#7C67F7] mt-4 md:mt-5 w-full md:w-[430px]">
                <h1 class="text-xl md:text-2xl font-bowlby mb-2 md:mb-3">To play, click</h1>
                <h1 class="text-xl md:text-2xl font-bowlby mb-2 md:mb-3">on Start.</h1>
                <div class="rounded-lg p-2 md:p-3 bg-[#3421A3] inline-block">
                    <h1 class="text-xs md:text-sm font-normal">SEND a link to your friend.</h1>
                </div>
            </div>
        </div>
        <div class="flex flex-col justify-start items-center w-full md:w-auto mb-5">
            <div class="flex flex-wrap gap-3 justify-center md:justify-start">

                
                <!-- Platform Picker -->
                <div class="relative">
                    <button @click="togglePlatformDropdown" class="w-full md:w-[189px] h-[60px] md:h-[78px] relative bg-[#a93075] rounded-[15px] overflow-hidden shadow border-2 border-[#778290] flex justify-start">
                        <div class="w-full h-[52px] md:h-[70px] relative bg-[#ff77c4] rounded-[15px] flex items-center justify-center px-5">
                            <h3 class="text-white text-base md:text-3xl font-normal">
                                {{ selectedPlatform ? selectedPlatform.toUpperCase() : 'PLATFORM' }}
                            </h3>
                        </div>
                    </button>
                    
                    <transition name="dropdown">
                        <div v-if="isPlatformDropdownOpen" class="absolute right-0 bottom-full md:bottom-auto md:top-full mb-2 md:mt-2 w-full md:w-[189px] bg-[#a93075] rounded-[15px] shadow border-2 border-[#778290] overflow-hidden z-10">
                            <div class="w-full bg-[#ff77c4] p-3 space-y-2">
                                <button @click="selectPlatform('eth')" class="w-full py-2 bg-[#d94197] rounded-full text-white text-sm font-normal">
                                    Ethereum
                                </button>
                                <button @click="selectPlatform('sui')" class="w-full py-2 bg-[#d94197] rounded-full text-white text-sm font-normal">
                                    Sui
                                </button>
                            </div>
                        </div>
                    </transition>
                </div>

                <!-- New Balance button with Deposit option -->
                <div class="relative" v-if="isConnected">
                    <button @click="toggleBalanceDropdown" class="w-[140px] md:w-[189px] h-[60px] md:h-[78px] relative bg-[#a93075] rounded-[15px] overflow-hidden shadow border-2 border-[#778290] flex justify-start">
                        <div class="w-full h-[52px] md:h-[70px] relative bg-[#ff77c4] rounded-[15px] flex items-center justify-center">
                            <h3 class="text-white text-lg md:text-3xl font-normal" :class="isConnected ? 'text-sm md:text-xl mt-1' : 'text-sm md:text-xl'">
                                {{ isBalanceChecking ? 'CHECKING...' : (isConnected ? formattedBalance : 'BALANCE') }}
                            </h3>
                        </div>
                    </button>
                    
                    <transition name="dropdown">
                        <div v-if="isBalanceDropdownOpen && isConnected" class="absolute right-0 md:right-auto md:left-0 top-full mt-2 w-full md:w-[189px] bg-[#30a975] rounded-[15px] shadow border-2 border-[#778290] overflow-hidden z-10">
                            <div class="w-full bg-[#a93075] p-3 space-y-2">
                                <button @click="showDepositModal" class="w-full py-2 bg-[#ff77c4] rounded-full text-white text-sm font-normal">
                                    Deposit
                                </button>
                            </div>
                        </div>
                    </transition>
                </div>

                <div class="relative">    
                    <button @click="toggleDropdown" class="w-[140px] md:w-[189px] h-[60px] md:h-[78px] relative bg-[#a93075] rounded-[15px] overflow-hidden shadow border-2 border-[#778290] flex justify-start">
                        <div class="w-full h-[52px] md:h-[70px] relative bg-[#ff77c4] rounded-[15px] flex items-center justify-center">
                            <h3 class="text-white text-lg md:text-3xl font-normal" :class="isConnected ? 'text-sm md:text-xl mt-1' : 'text-sm md:text-xl'">
                                {{ isChecking ? 'CHECKING...' : (isConnected ? truncatedAddress : 'LOGIN') }}
                            </h3>
                        </div>
                    </button>
                    
                    <transition name="dropdown">
                        <div v-if="isDropdownOpen && !isConnected" class="absolute right-0 bottom-full md:bottom-auto md:top-full mb-2 md:mt-2 w-full md:w-[189px] bg-[#a93075] rounded-[15px] shadow border-2 border-[#778290] overflow-hidden z-10">
                            <div class="w-full bg-[#ff77c4] p-3 space-y-2">
                                <button @click="connectWallet" class="w-full py-2 bg-[#d94197] rounded-full text-white text-sm font-normal">
                                    Connect Wallet
                                </button>
                            </div>
                        </div>
                    </transition>
                    <transition name="dropdown">
                        <div v-if="isDropdownOpen && isConnected" class="absolute right-0 bottom-full md:bottom-auto md:top-full mb-2 md:mt-2 w-full md:w-[189px] bg-[#a93075] rounded-[15px] shadow border-2 border-[#778290] overflow-hidden z-10">
                            <div class="w-full bg-[#ff77c4] p-3 space-y-2">
                                <button @click="routeToProfile" class="w-full py-2 bg-[#d94197] rounded-full text-white text-sm font-normal">
                                    My Profile
                                </button>
                                <button @click="disconnect" class="w-full py-2 bg-[#d94197] rounded-full text-white text-sm font-normal">
                                    Disconnect
                                </button>
                            </div>
                        </div>
                    </transition>
                </div>

                <!-- New Play Now button, shown only when wallet is connected -->
                <div v-if="isConnected" class="relative w-full md:w-auto">
                    <div class="absolute inset-0 bg-[#1647aa] rounded-[15px] shadow border-2 border-[#778290]"></div>
                    <div class="absolute inset-0 bottom-1 md:bottom-2 bg-[#2b64d6] rounded-[15px]"></div>
                    <div class="relative py-3 md:py-4 px-4 md:px-6 text-white text-2xl md:text-3xl font-normal text-center">START</div>
                    <button @click="openLobbyModal" class="absolute inset-0 w-full h-full opacity-0"></button>
                </div>
            </div>
        </div>
    </div>

    <!-- Deposit Modal -->
    <div v-if="isDepositModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-6 w-96">
            <h2 class="text-2xl font-bold mb-4">Deposit</h2>
            <input v-model="depositAmount" type="number" step="0.01" min="0" class="w-full p-2 border rounded mb-4" placeholder="Amount" :disabled="isDepositing">
            <div class="flex justify-end space-x-2">
                <button @click="closeDepositModal" class="px-4 py-2 bg-gray-300 rounded" :disabled="isDepositing">Cancel</button>
                <button @click="confirmDeposit" class="px-4 py-2 bg-green-500 text-white rounded" :disabled="isDepositing">
                    <span v-if="!isDepositing">Confirm</span>
                    <span v-else class="flex items-center">
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Depositing...
                    </span>
                </button>
            </div>
        </div>
    </div>

    <!-- Lobby Modal -->
    <div v-if="isLobbyModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white dark:bg-gray-800 rounded-lg p-6 w-96">
            <h2 class="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Lobbies</h2>
            <div class="space-y-4">
                <button @click="goToLobbies" class="w-full py-2 bg-[#2b64d6] text-white rounded-full">
                    Join Lobby
                </button>
                <button @click="createLobby" class="w-full py-2 bg-[#41d997] text-white rounded-full">
                    Create Lobby
                </button>
            </div>
            <button @click="closeLobbyModal" class="mt-4 px-4 py-2 bg-gray-300 dark:bg-gray-600 rounded-full text-gray-800 dark:text-white">
                Cancel
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Web3 from 'web3';
import abi from '@/assets/abi.json';
import userService from '@/services/userService';
import { ethers } from 'ethers';
import { SuiInBrowser, Transaction } from 'suidouble';

const contractAddress = '0xb712dD42885b866f15167E64f58C34F734526DB0';
const suiContractAddress = '0x66f02531bfd49719fc17701e84db2a1c2a91581e24fd7dfba7e9de713afc4f50';

const isDropdownOpen = ref(false);
const isBalanceDropdownOpen = ref(false);
const isBalanceChecking = ref(false);
const balance = ref('0');
const isDepositModalOpen = ref(false);
const depositAmount = ref('');
const isDepositing = ref(false); // New ref for tracking deposit state
const isChecking = ref(true);
const account = ref('');
const web3 = ref(null);
const isLobbyModalOpen = ref(false);
const router = useRouter();
const store = useStore();
const selectedPlatform = computed(() => store.getters.getSelectedPlatform);
const isPlatformDropdownOpen = ref(false);
const suiInBrowser = ref(null);
const suiWallet = ref(null);

const isConnected = computed(() => !!account.value);

const truncatedAddress = computed(() => {
    if (account.value) {
        return `${account.value.slice(0, 6)}...${account.value.slice(-4)}`;
    }
    
    return '';
});

const toggleDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value;
};

// const isDarkMode = ref(false);

// const toggleTheme = () => {
//     isDarkMode.value = !isDarkMode.value;
//     updateTheme();
// };

// const updateTheme = () => {
//     if (isDarkMode.value) {
//         document.documentElement.classList.add('dark');
//     } else {
//         document.documentElement.classList.remove('dark');
//     }
// };

onMounted(async () => { 
    isChecking.value = true;

    try {
        connectWallet();

        suiInBrowser.value = await SuiInBrowser.getSingleton({ defaultChain: 'sui:devnet' });
        suiInBrowser.value.addEventListener('connected', () => {
            loginWithSui();
            console.log(suiInBrowser.value.getCurrentChain());
        });

        console.log('asdad');
    } catch (error) {
        console.error('Error checking wallet connection:', error);
    } finally {
        isChecking.value = false;
    }
});

const createLobby = async () => {
    try {
        const lobby = await userService.createLobby();
        router.push({ path: '/play/' + lobby.id });
        closeLobbyModal();
    } catch (error) {
        console.error('Error creating lobby:', error);
    }
};

const toggleBalanceDropdown = () => {
    if (isConnected.value) {
        isBalanceDropdownOpen.value = !isBalanceDropdownOpen.value;
    }
};

const refreshBalance = async () => {
    if (isConnected.value) {
        isBalanceChecking.value = true;
        try {
            if (selectedPlatform.value === 'eth') {
                const contract = new web3.value.eth.Contract(abi, contractAddress);
                const balanceWei = await contract.methods.getBalance(account.value).call();
                console.log('Balance', balanceWei);
                balance.value = web3.value.utils.fromWei(balanceWei, 'ether');
            } else if (selectedPlatform.value === 'sui') {
                const suiMaster = await suiInBrowser.value.getSuiMaster();
                const contract = await suiMaster.addPackage({ id: suiContractAddress });

                // Call the get_balance function


                const tx = new Transaction();
                tx.setGasBudget(10000);


                const result = await contract.moveCall('RockPaperScissors', 'get_balance', { tx: tx });

                // eslint-disable-next-line
                const balanceInMist = BigInt(result);
                
                // Convert from Mist (10^-9 SUI) to SUI
                balance.value = Number(balanceInMist) / 1e9;

                console.log('Sui balance:', balance.value);
            }
        } catch (error) {
            console.error('Error refreshing balance:', error);
        } finally {
            isBalanceChecking.value = false;
        }
    }
};

const formattedBalance = computed(() => {
    return parseFloat(balance.value).toFixed(4);
});

const showDepositModal = () => {
    isDepositModalOpen.value = true;
    isBalanceDropdownOpen.value = false;
};

const closeDepositModal = () => {
    isDepositModalOpen.value = false;
    depositAmount.value = '';
};

const confirmDeposit = async () => {
    if (!isConnected.value || !depositAmount.value) return;

    try {
        isDepositing.value = true; // Set depositing state to true

        if (selectedPlatform.value === 'eth') {
            await depositEth();
        } else if (selectedPlatform.value === 'sui') {
            await depositSui();
        }
        
        await refreshBalance();
        closeDepositModal();
    } catch (error) {
        console.error('Error during deposit:', error);
        alert(`Deposit failed: ${error.message}`);
    } finally {
        isDepositing.value = false; // Set depositing state back to false
    }
};

const depositEth = async () => {
    const contract = new web3.value.eth.Contract(abi, contractAddress);
    const depositAmountWei = ethers.parseEther(depositAmount.value.toString());
    console.log(depositAmountWei);


    await contract.methods.deposit().send({
        from: account.value,
        value: depositAmountWei
    });
};

const depositSui = async () => {
    const depositAmountMist = Math.floor(parseFloat(depositAmount.value) * 1e9);
    const tx = new Transaction();
    
    tx.transferObjects([tx.gas], depositAmountMist);
    tx.setGasBudget(10000);

    const suiMaster = suiInBrowser.value.getSuiMaster();

    const contract = suiMaster.addPackage({ id: suiContractAddress });

    const result = await contract.moveCall(
        'RockPaperScissors',
        'deposit',
        [tx.gas, account.value],
        {},
    );

    console.log('Sui deposit transaction:', result);
};

const togglePlatformDropdown = () => {
    isPlatformDropdownOpen.value = !isPlatformDropdownOpen.value;
};

const selectPlatform = (platform) => {
    store.dispatch('selectPlatform', platform);
    isPlatformDropdownOpen.value = false;
    disconnect();
};

const connectWallet = async () => {
    if (selectedPlatform.value === 'eth') {
        await connectMetaMask();
    } else if (selectedPlatform.value === 'sui') {
        suiInBrowser.value = await SuiInBrowser.getSingleton({ debug: false, defaultChain: 'sui:devnet' });
        suiInBrowser.value.connect(suiInBrowser.value.adapters['Sui Wallet']);
    } else {
        console.error('Please select a platform first');
    }
};

const connectMetaMask = async () => {
    try {
        if (typeof window.ethereum !== 'undefined') {
            web3.value = new Web3(window.ethereum);
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            
            if (accounts.length > 0) {
                account.value = accounts[0];
                console.log('Connected to MetaMask:', account.value);

                const nonce = await userService.getNonce(account.value);
                const signer = await (new ethers.BrowserProvider(window.ethereum).getSigner());
                const signature = await signer.signMessage('Nonce: ' + nonce);

                const response = await userService.login(signature, account.value, 'eth');

                localStorage.setItem('token', response.token);
                store.commit('setUser', response.user);

                isDropdownOpen.value = false;
                refreshBalance();
            } else {
                throw new Error('No MetaMask accounts found');
            }
        } else {
            throw new Error('MetaMask is not installed');
        }
    } catch (error) {
        console.error('Error connecting to MetaMask:', error);
        alert(`Failed to connect to MetaMask: ${error}`);
    }
};

const loginWithSui = async () => {
    try {
        const address = suiInBrowser.value.getAddress();

        const nonce = await userService.getNonce(address);
        const signData = new TextEncoder().encode(`Nonce: ${nonce}`);

        const response = await userService.login(
            signData,
            address,
            'sui'
        );

        account.value = response.user.address;

        localStorage.setItem('token', response.token);
        store.commit('setUser', response.user);

        isDropdownOpen.value = false;
        // Refresh balance after successful login
        await refreshBalance();
    } catch (error) {
        console.error('Error logging in with Sui:', error);
        alert(`Failed to login with Sui: ${error.message}`);
    }
};


watch(() => account.value, () => {
    if (isConnected.value) {
        refreshBalance();
    }
});

// Check for user's preferred color scheme
// if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    // isDarkMode.value = true;
// }

// updateTheme();

const openLobbyModal = () => {
    isLobbyModalOpen.value = true;
};

const closeLobbyModal = () => {
    isLobbyModalOpen.value = false;
};

const goToLobbies = () => {
    router.push('/lobbies');
    closeLobbyModal();
};

const routeToProfile = () => {
    router.push('/profile');
    isDropdownOpen.value = false;
};

const disconnect = async () => {
    try {
        account.value = '';
        localStorage.removeItem('token');
        store.commit('setUser', null);
        isDropdownOpen.value = false;
        
        if (selectedPlatform.value === 'sui') {
            suiWallet.value = null;
        }
    } catch (error) {
        console.error('Error disconnecting:', error);
    }
};
</script>

<style scoped>
.dropdown-enter-active,
.dropdown-leave-active {
    transition: opacity 300ms, transform 300ms;
}

.dropdown-enter-from,
.dropdown-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}
</style>



