<template>
  <div class="bg-gradient">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');
@media (max-width: 640px) {
  .font-bowlby {
    word-break: break-word;
  }
}

#app {
  font-family: 'Bowlby One SC', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-bowlby {
  font-family: 'Bowlby One SC', cursive;
}

.bg-gradient {
  background: #3421A3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
</style>