<template>
    <img v-if="!opponentJoined" src="../assets/play_grid.svg" alt="Play Grid" class="w-1/2 h-1/2 -bottom-20 -left-1/4 absolute">
    <img v-if="!opponentJoined" src="../assets/play_left_hand.svg" alt="Login Left Hand" class="w-1/2 h-4/5 -bottom-20 -left-32 absolute">
    <img v-if="!opponentJoined" src="../assets/play_right_hand.svg" alt="Login Right Hand" class="w-1/2 h-4/5 bottom-0 right-0 absolute">
    <div class="flex flex-col md:flex-row items-center md:items-start justify-between text-white p-4 md:p-10" v-if="!opponentJoined"> 
        <div class="flex flex-col items-center md:items-start justify-start mb-6 md:mb-0">
            <h3 class="text-4xl md:text-7xl font-normal">Rock Paper</h3>
            <h3 class="text-4xl md:text-7xl font-normal mt-2">Scissors</h3>
        </div>
        <div class="flex flex-col items-center justify-center w-full">
            <img src="../assets/copy.svg" alt="Copy" class="w-64 md:w-[500px] h-auto cursor-pointer" @click="copyLink">
            <div class="mt-6 md:mt-10 flex justify-center items-center bg-[#523BD4] rounded-3xl p-3 md:p-4 bg-opacity-80 border border-[#7C67F7]">
                <h3 class="text-base md:text-3xl font-normal">{{ truncatedLink }}</h3>
            </div>
            <h3 class="text-sm md:text-base font-normal mt-3 md:mt-5 text-center">Share with your friend to play</h3>
            <div class="mt-8 md:mt-16 flex flex-col items-center justify-center">
                <h3 class="text-4xl md:text-6xl font-normal">Waiting for</h3>
                <h3 class="text-4xl md:text-6xl font-normal">Opponent to</h3>
                <h3 class="text-3xl md:text-5xl font-normal text-[#FF77C4]">Join</h3>

                <div class="relative w-full max-w-xs md:max-w-md mt-6 md:mt-9">
                    <div class="absolute inset-0 bg-[#1647aa] rounded-[15px] shadow border-2 border-[#778290]"></div>
                    <div class="absolute inset-0 bottom-2 bg-[#2b64d6] rounded-[15px]"></div>
                    <div class="relative py-4 px-6 text-white text-3xl font-normal text-center">Quit</div>
                    <button @click="leaveGame" class="absolute inset-0 w-full h-full opacity-0"></button>
                </div>
            </div>
        </div>
        <div class="flex flex-col items-center md:items-end justify-start mt-6 md:mt-0 md:me-10">
            <h3 class="text-4xl md:text-7xl font-normal text-[#FF77C4]">Online</h3>
        </div>
    </div>
    <div class="flex flex-col w-full justify-start items-center p-4 md:p-0" v-else>
        <h3 class="text-[#FF77C4] text-5xl md:text-8xl font-normal mb-6 md:mb-10">Round {{ currentRound }}</h3>
        <div class="flex flex-col md:flex-row justify-between items-center w-full px-4 md:px-20">
            <div class="flex flex-col justify-center items-center mb-6 md:mb-0">
                <h3 class="text-white text-2xl md:text-3xl mb-2 md:mb-4">Your Score</h3>
                <div class="flex gap-2 justify-center">
                    <img v-for="i in 3" :key="i" :src="i <= myScore ? starFilled : starEmpty" alt="Star" class="w-12 h-12 md:w-20 md:h-20">
                </div>
            </div>
            <div class="flex flex-col justify-center items-center my-6 md:my-0">
                <h3 class="text-white text-2xl md:text-3xl mb-2 md:mb-4">VS</h3>
                <div class="bg-[#FF77C4] rounded-full w-16 h-16 md:w-24 md:h-24 flex items-center justify-center">
                    <span class="text-white text-2xl md:text-4xl">{{ currentRound }} / 3</span>
                </div>
            </div>
            <div class="flex flex-col justify-center items-center">
                <h3 class="text-white text-2xl md:text-3xl mb-2 md:mb-4">Opponent's Score</h3>
                <div class="flex gap-2 justify-center">
                    <img v-for="i in 3" :key="i" :src="i <= opponentScore ? starFilled : starEmpty" alt="Star" class="w-12 h-12 md:w-20 md:h-20">
                </div>
            </div>
        </div>
        <div class="mt-16 md:mt-16 flex justify-end md:justify-end" v-if="!lastMove">
            <button @click="playMove('Rock')" class="rounded-full text-lg p-5 md:text-xl mr-2 md:mr-4 bg-[#FF77C4]">
                <img src="../assets/rock_hand.svg" alt="rock" class="h-10 w-10">
            </button>
            <div class="flex flex-col justify-end">
              <button @click="playMove('Paper')" class="rounded-full text-lg p-5 md:text-xl mr-2 md:mr-4 bg-[#DF9624]">
                <img src="../assets/paper_hand.svg" alt="paper" class="h-10 w-10">
              </button>
            </div>
            <button @click="playMove('Scissors')" class="rounded-full text-lg p-5 md:text-xl bg-[#FF77C4]">
              <img src="../assets/scissors_hand.svg" alt="scissors" class="h-10 w-10">
            </button>
        </div>
        <div class="mt-6 md:mt-10 text-white text-xl md:text-2xl text-center" v-if="roundWinner">
            <p>{{ roundWinner === 'You' ? 'You won' : 'Opponent won' }} this round!</p>
        </div>
        <div class="mt-6 md:mt-10 text-white text-2xl md:text-4xl text-center" v-if="gameWinner">
            <p>{{ gameWinner === 'You' ? 'Congratulations! You won the game!' : 'Game over. Your opponent won.' }}</p>
        </div>
    </div>
    <div v-if="opponentDisconnected" class="mt-16 flex flex-col items-center justify-center">
        <WaitForReconnect @timeUp="handleReconnectTimeUp" />
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import store from '@/store';
import userService from '@/services/userService';
import { ethers } from 'ethers';
import Web3 from 'web3';
import WaitForReconnect from '@/components/WaitForReconnect.vue';
import router from '@/router';

const isChecking = ref(false);
const route = useRoute();
const gameId = route.params.id;
const opponentJoined = ref(false);
const lastMove = ref('');
const opponentMove = ref('');
const roundWinner = ref('');
const gameWinner = ref('');
const currentRound = ref(1);
const account = ref('');
let socket;

// Web3 setup
let web3 = ref(null);
let contract;
// const contractAddress = '0xb712dD42885b866f15167E64f58C34F734526DB0'; // Replace with your actual contract address

const truncatedLink = computed(() => {
  return `${window.location.host}/play/${route.params.id}`.slice(0, 30) + '...';
});

const fullLink = computed(() => {
  return `${window.location.origin}/play/${route.params.id}`;
});

const copyLink = () => {
  navigator.clipboard.writeText(fullLink.value)
    .then(() => {
      alert('Link copied to clipboard!');
    })
    .catch((err) => {
      console.error('Failed to copy link: ', err);
    });
};

const opponentDisconnected = ref(false);
const myScore = ref(0);
const opponentScore = ref(0);

const starFilled = new URL('../assets/star_filled.svg', import.meta.url).href;
const starEmpty = new URL('../assets/star.svg', import.meta.url).href;

const timeRemaining = ref(5);
const timerInterval = ref(null);

onMounted(async () => {

  isChecking.value = true;

  try {
      if (typeof window.ethereum !== 'undefined') {
          web3.value = new Web3(window.ethereum);
          const accounts = await web3.value.eth.getAccounts();  

          if (accounts.length > 0) {
              account.value = accounts[0];
              console.log('Wallet already connected:', account.value);

              const nonce = await userService.getNonce(account.value);

              const signer = await (new ethers.BrowserProvider(window.ethereum).getSigner());

              const signature = await signer.signMessage('Nonce: ' + nonce);

              const response = await userService.login(signature, account.value, 'eth');

              localStorage.setItem('token', response.token);
              store.commit('setUser', response.user);

              setupWebSocket();
          } else {
              console.log('No wallet connected');
          }
      } else {
          console.log('MetaMask is not installed');
      }

      console.log('asdad');
  } catch (error) {
      console.error('Error checking wallet connection:', error);
  } finally {
      isChecking.value = false;
  }
});

onUnmounted(() => {
  if (socket) {
    socket.close();
  }
  clearInterval(timerInterval.value);
});


const setupWebSocket = () => {
  const wsUrl = `https://api.rpsbattle.fun/ws/${gameId}/${store.state.user.id}`;
  socket = new WebSocket(wsUrl);

  socket.onopen = () => {
    console.log('WebSocket connection established');
    socket.send(JSON.stringify({ type: 'check_opponent', userId: store.state.user.id }));
    socket.send(JSON.stringify({ type: 'join_game', userId: store.state.user.id }));
  };

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    console.log(data);

    if (data.type === 'opponent_status') {
      opponentJoined.value = data.joined;
    } else if (data.type === 'game_started') {
      opponentJoined.value = true;
      startRoundTimer();
    } else if (data.type === 'round_result') {
      handleRoundResult(data);
    } else if (data.type === 'game_result') {
      handleGameResult(data);
    } else if (data.type === 'join_error') {
      console.error('Error joining game:', data.message);
    } else if (data.type === 'player_left') {
      opponentDisconnected.value = true;
    } else if (data.type === 'move_played') {
      handleMovePlayed(data);
    } 
  };

  socket.onerror = (error) => {
    console.error('WebSocket error:', error);
    leaveRoom();
  };

  socket.onclose = () => {
    console.log('WebSocket connection closed');
    leaveRoom();
  };
};

const leaveRoom = () => {
  // Implement any cleanup logic here
  opponentJoined.value = false;
  lastMove.value = '';
  opponentMove.value = '';
  roundWinner.value = '';
  gameWinner.value = '';
  currentRound.value = 1;

  // Navigate back to the home page or lobby
  router.push('/');
};

const playMove = async (move) => {
  if (timeRemaining.value === 0) return;

  try {
    // Send move to smart contract
    if (contract) {
      const moveMap = { 'Rock': 1, 'Paper': 2, 'Scissors': 3 };
      const moveNumber = moveMap[move];
      const accounts = await web3.value.eth.getAccounts();
      await contract.methods.playMove(gameId, moveNumber).send({ from: accounts[0] });
    }

    // Send move to WebSocket
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ type: 'move_played', move: move, userId: store.state.user.id }));  
    }

    lastMove.value = move;
    console.log(`Move ${move} played successfully`);
  } catch (error) {
    console.error('Error playing move:', error);
  }
};

const leaveGame = () => {
  console.log('Leave room');
  if (socket && socket.readyState === WebSocket.OPEN) {
    socket.send(JSON.stringify({ type: 'leave_game' }));
  }

  leaveRoom();
};

const handleReconnectTimeUp = () => {
  opponentDisconnected.value = false;
  // You might want to show a message that the opponent didn't reconnect
  // and handle the game ending logic here if it's not handled by the server
};

// const handleGameOver = (data) => {
//   opponentDisconnected.value = false;
//   gameWinner.value = data.winner === store.state.user.id ? 'You' : 'Opponent';
//   // You might want to show the reason for game over (e.g., "Opponent did not reconnect")
//   // and handle any other end-of-game logic

// };

const handleMovePlayed = (data) => {
  if (data.playerId === store.state.user.id) {
    lastMove.value = data.move;
  } else {
    opponentMove.value = data.move;
  }
};

const handleRoundResult = (data) => {
  currentRound.value = data.round;
  roundWinner.value = data.roundWinner === store.state.user.id ? 'You' : 'Opponent';
  myScore.value = data.scores[store.state.user.id];
  opponentScore.value = data.scores[Object.keys(data.scores).find(id => id !== store.state.user.id)];
  
  // Reset for next round
  lastMove.value = '';
  opponentMove.value = '';
  startRoundTimer();
};

const handleGameResult = (data) => {
  gameWinner.value = data.winner === store.state.user.id ? 'You' : 'Opponent';
  // You might want to show final scores or other end-game information here
};

const startRoundTimer = () => {
  timeRemaining.value = 5;
  clearInterval(timerInterval.value);
  timerInterval.value = setInterval(() => {
    if (timeRemaining.value > 0) {
      timeRemaining.value--;
    } else {
      clearInterval(timerInterval.value);
    }
  }, 1000);
};
</script>

