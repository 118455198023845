<template>
    <div class="flex flex-col items-center justify-center">
      <h3 class="text-6xl font-normal text-[#FF77C4]">Rakip Bağlantısı Kesildi</h3>
      <p class="text-2xl mt-4">Rakibinizin yeniden bağlanması için bekleniyor...</p>
      <div class="mt-6 w-64 h-4 bg-gray-200 rounded-full overflow-hidden">
        <div
          class="h-full bg-blue-500 transition-all duration-1000 ease-linear"
          :style="{ width: `${progressPercentage}%` }"
        ></div>
      </div>
      <p class="mt-2 text-xl">{{ remainingTime }} saniye kaldı</p>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted, defineProps, defineEmits } from 'vue';
  
  const props = defineProps({
    duration: {
      type: Number,
      default: 15,
    },
  });
  
  const emit = defineEmits(['timeUp']);
  
  const remainingTime = ref(props.duration);
  const progressPercentage = ref(0);
  let timer;
  
  const updateTimer = () => {
    remainingTime.value--;
    progressPercentage.value = ((props.duration - remainingTime.value) / props.duration) * 100;
  
    if (remainingTime.value <= 0) {
      clearInterval(timer);
      emit('timeUp');
    }
  };
  
  onMounted(() => {
    timer = setInterval(updateTimer, 1000);
  });
  
  onUnmounted(() => {
    clearInterval(timer);
  });
  </script>