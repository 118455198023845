import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import PlayView from '@/views/PlayView.vue'
import LobbiesView from '@/views/LobbiesView.vue'
import ProfileView from '@/views/ProfileView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView
  },
  {
    path: '/play/:id',
    name: 'play',
    component: PlayView,
    props: true
  },
  {
    path: '/lobbies',
    name: 'lobbies',
    component: LobbiesView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
