<template>
  <div class="flex flex-col md:flex-row items-start justify-between w-full min-h-screen p-4 md:p-10 overflow-y-auto">
    <div class="fixed bottom-0 right-0 max-w-full z-10 pointer-events-none">
      <img src="../assets/profile_grid.svg" alt="profile" class="opacity-50">
    </div>
    <div class="flex flex-col justify-start items-start text-gray-900 dark:text-white w-full md:w-auto z-10">
      <h1 class="text-5xl md:text-8xl font-bowlby mb-4 md:mb-8">Rock Paper</h1>
      <h1 class="text-5xl md:text-8xl font-bowlby mb-4 md:mb-8">Scissors</h1>
      <h2 class="text-4xl md:text-7xl font-bowlby mb-6 md:mb-12 text-[#FF77C4]">History</h2>

      <div class="w-full max-w-4xl overflow-x-auto bg-[#523BD4] p-3 md:p-5 rounded-xl mb-6 h-[300px] md:h-[400px]">
        <table class="w-full border-collapse bg-white dark:bg-gray-800 shadow-lg rounded-lg">
          <thead>
            <tr class="bg-[#1647aa] rounded-[15px]">
              <th class="px-6 py-3 text-left text-white text-lg font-normal">Rival</th>
              <th class="px-6 py-3 text-left text-white text-lg font-normal">History</th>
              <th class="px-6 py-3 text-left text-white text-lg font-normal">Earning</th>
              <th class="px-6 py-3 text-left text-white text-lg font-normal">Conclusion</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 mt-10 bg-[#523BD4]">
            <tr v-for="game in history" :key="game.id">
              <td class="px-6 py-4 whitespace-nowrap text-sm">{{ game.rival }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm">{{ game.date }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm">{{ game.earning }} ETH</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm">
                <span :class="getResultClass(game.result)">
                  {{ game.result }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
    <!-- Mobile menu dropdown -->
    <div class="md:hidden relative w-full mb-4">
      <button v-on:click="toggleMobileMenu" class="w-full bg-[#a93075] text-white py-2 px-4 rounded-lg flex justify-between items-center relative z-30 cursor-default">
        <span>Menu</span>
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      <div v-if="isMobileMenuOpen" class="absolute top-full left-0 w-full mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-lg z-30">
        <div class="p-4 space-y-3">
          <button @click="toggleBalanceDropdown" class="w-full h-[78px] relative bg-[#a93075] rounded-[15px] overflow-hidden shadow border-2 border-[#778290] flex justify-start">
            <div class="w-full h-[70px] relative bg-[#ff77c4] rounded-[15px] flex items-center justify-center">
              <h3 class="text-white text-3xl font-normal" :class="isConnected ? 'text-xl mt-1' : 'text-xl'">
                {{ isBalanceChecking ? 'CHECKING...' : (isConnected ? formattedBalance : 'BALANCE') }}
              </h3>
            </div>
          </button>

          <button class="w-full relative flex flex-col items-center bg-[#a93075] rounded-[15px] h-[78px] shadow border-2 border-[#778290] overflow-hidden group justify-start">
            <div class="h-[70px] w-full bg-[#ff77c4] rounded-[14px] flex px-4 py-2 items-center justify-between">
              <div class="relative flex items-center overflow-hidden">
                <span class="text-white text-lg truncate">{{ store.state.user?.address ?? '0x' }}</span>
              </div>
              <div class="relative ml-2 flex-shrink-0">
                <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 29.3332C23.364 29.3332 29.3333 23.3638 29.3333 15.9998C29.3333 8.63584 23.364 2.6665 16 2.6665C8.63596 2.6665 2.66663 8.63584 2.66663 15.9998C2.66663 23.3638 8.63596 29.3332 16 29.3332Z" stroke="white" stroke-width="4" stroke-linejoin="round"/>
                  <path d="M22 14L16 20L10 14" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>

    <!-- Desktop buttons -->
    <div class="hidden md:flex flex-col justify-start items-start mt-6 md:mt-0 w-full md:w-auto z-10">
      <div class="flex flex-col md:flex-row gap-3 md:gap-5 w-full">
        <button @click="toggleBalanceDropdown" class="w-full md:w-[189px] h-[78px] relative bg-[#a93075] rounded-[15px] overflow-hidden shadow border-2 border-[#778290] flex justify-start">
          <div class="w-full h-[70px] relative bg-[#ff77c4] rounded-[15px] flex items-center justify-center">
            <h3 class="text-white text-3xl font-normal" :class="isConnected ? 'text-xl mt-1' : 'text-xl'">
              {{ isBalanceChecking ? 'CHECKING...' : (isConnected ? formattedBalance : 'BALANCE') }}
            </h3>
          </div>
        </button>

        <button class="relative flex flex-col items-center bg-[#a93075] rounded-[15px] h-[78px] shadow border-2 border-[#778290] overflow-hidden group justify-start">
          <div class="h-[70px] w-full bg-[#ff77c4] rounded-[14px] flex px-4 py-2">
            <div class="relative flex items-center">
              <span class="text-white text-3xl">{{ store.state.user?.address ?? '0x' }}</span>
            </div>
            <div class="relative ml-4">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 29.3332C23.364 29.3332 29.3333 23.3638 29.3333 15.9998C29.3333 8.63584 23.364 2.6665 16 2.6665C8.63596 2.6665 2.66663 8.63584 2.66663 15.9998C2.66663 23.3638 8.63596 29.3332 16 29.3332Z" stroke="white" stroke-width="4" stroke-linejoin="round"/>
                <path d="M22 14L16 20L10 14" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
// import userService from '@/services/userService';
import store from '@/store';
import {  ref } from 'vue';

const history = ref([]);
const isMobileMenuOpen = ref(false);

const getResultClass = (result) => {
  return result === 'Win'
    ? 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800'
    : 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800';
};

const toggleMobileMenu = () => {
    console.log('asda');
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
};

// onMounted(async () => {
//     history.value = await userService.getHistory();
// });
</script>

